<template>
  <router-link :to="to">
    <el-card>
      <el-row type="flex">
        <div class="card-icon"><i :class="icon"></i></div>
        <div class="card-text one-line">
          <div>{{name}}</div>
        </div>
      </el-row>
    </el-card>
  </router-link>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "home-block-button",
  components: {},

  props: {
    to: {type: Object, required: true},
    icon: {type: String, required: true},
    name: {type: String, required: true},
  },

  data() {
    return {

    }
  },
  mounted() {
  },
  watch: {},
  methods: {
  }
}
</script>

<style lang="scss">
@import "@/assets/css/project-variables";

</style>